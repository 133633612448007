<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="cod_funcao"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Meta Função</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="202"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FilterMetaFuncao
            @selectedFilters="fetchMetaFuncao((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>

      <template v-slot:[`item.funcao`]="{ item }">
        {{ `${item.cod_funcao} - ${item.funcao}` | TitleCase }}
      </template>

      <template v-slot:[`item.qtd_indicadores`]="{ item }">
        {{ item.dados.length }}
      </template>

      <!-- expanded -->

      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <v-btn small text v-if="!isExpanded" @click="expand(!isExpanded)">
          expandir
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn text small v-else @click="expand(!isExpanded)">
          ocultar
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-0" :colspan="headers.length">
          <ListaMetaFuncao
            :items="item.dados"
            @reload="fetchMetaFuncao(filters)"
          />
        </td>
      </template>
    </BaseTable>
    <ModalCadMetaFuncao
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import comissao from "@/services/http/comissaoService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FilterMetaFuncao from "./Filter";
import ModalCadMetaFuncao from "./ModalCadMetaFuncao";
import ListaMetaFuncao from "./ListaMetaFuncao.vue";
import banderiras from "@/mixins/bandeiras";

export default {
  components: {
    BaseTable,
    RegisterBottom,
    FilterMetaFuncao,
    ModalCadMetaFuncao,
    ListaMetaFuncao
  },

  mixins: [banderiras],

  data() {
    return {
      loading: false,
      dialog: false,
      singleExpand: false,
      labelBtn: "",
      expanded: [],
      filters: "",
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Função", value: "funcao" },
        { text: "QTD Indicadores", value: "qtd_indicadores", align: "center" }
      ],
      info: []
    };
  },

  methods: {
    async fetchMetaFuncao(filters) {
      this.loading = true;
      let {
        data: { data }
      } = await comissao()
        .metaFuncao()
        .show({ per_page: -1, ...filters });

      this.loading = false;
      const obj = this.groupByCompany(data, "cod_funcao");
      this.info = Object.values(obj);
    },

    groupByCompany(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || {
          id_band: currentValue.id_band,
          cod_funcao: currentValue.cod_funcao,
          funcao: currentValue.funcao,
          dados: []
        }).dados.push(currentValue);
        return result;
      }, {});
    }
  },

  mounted() {
    this.fetchMetaFuncao();
  }
};
</script>

<style></style>
