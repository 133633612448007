<template>
  <v-container class="container-card">
    <v-card>
      <MetaFuncao />
    </v-card>
  </v-container>
</template>

<script>
import MetaFuncao from "@/components/parametros/metaFuncao/MetaFuncao";
export default {
  components: {
    MetaFuncao
  },

};
</script>
